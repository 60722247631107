<template>
  <div style="min-width: 1412px; padding: 0 8%">
    <div class="contents" style="display: flex;justify-content: space-between;align-items: center; max-width: 100%;">
      <div style="width: 49.5%; height:100%; margin-right: 10px;">
        <div class="portal-btn">
          <div class="portal-admin-btn" v-bind:class="[userType === 'Admin' ? 'portal-admin-btn':'portal-admin-btn no-portal']">
            <div class="portal-btn-content">
              <h2>{{ $t("portal-button-system") }}</h2>
              <p>전체적인 사용자 계정 관리 및 시스템에 관리를<br> 보실 수 있습니다.</p>
              <button @click="moveToAdminMain">들어가기</button>
            </div>
            <img src="@/assets/images/ic_portal_admin.png">
          </div>
          <div class="portal-map-btn">
            <div class="portal-btn-content">
              <h2>{{ $t("portal-button-site") }}</h2>
              <p>전국에 설치된 스테이션 및 드론 비행에 대한<br> 지도 현황페이지 입니다. </p>
              <button @click="moveToSiteMap">들어가기</button>
            </div>
            <img src="@/assets/images/ic_portal_map.png">
          </div>
        </div>
        <div class="box boxCol3 mainList flex" style="width: 100%; height: calc(75% - 20px);margin-bottom: 20px;">
          <h2 class="bold">
            {{ $t("top-select-site-view-list") }}
          </h2>

          <!-- 정렬 버튼 -->
          <div class="flex" style="width: inherit;">
            <input
                type="text"
                v-model="keyword"
                style="width: 100%;"
                @keydown.enter="search"
            />
            <div class="buttonWrap" style="display: flex; margin-top: 0;">
              <button class="point small" @click="search" style="width: 90px; margin-left: 20px;background: #0080FF;border: 1px solid #0080FF;border-radius: 5px">
                {{ $t("btn-searching") }}
              </button>
              <button class="small margin6" @click="reset" style="width: 90px;border:1px solid #ddd;border-radius:5px;color:#454545;">
                {{ $t("btn-reset") }}
              </button>
            </div>
          </div>
          <div class="flexB" style="width: inherit; align-items: center;">
            <div class="filter flexB" style="align-items: center;" >
              <span style="font-size: 1.2rem; font-weight: 400; margin: 0;">
                {{ $t("user-home-site-sort-order") }}│
              </span>
              <div class="flex" style="margin-left: 10px; margin-bottom: 0px; line-height: 0px;">
                <!--            <p class="bold">{{ $t("event-data-site-status") }}</p>-->
                <label
                ><input
                    type="radio"
                    name="sortBtn"
                    checked
                    v-model="sort"
                    value="viewId"
                    @change="radioChange()"
                />{{ $t("user-home-site-sort-by-viewId") }}</label
                >
                <label
                ><input
                    type="radio"
                    name="sortBtn"
                    v-model="sort"
                    value="title"
                    @change="radioChange()"
                />{{ $t("user-home-site-sort-by-site-name") }}</label
                >
                <label
                ><input
                    type="radio"
                    name="sortBtn"
                    v-model="sort"
                    value="managerDepartmentTitle"
                    @change="radioChange()"
                />{{ $t("user-home-site-sort-by-manager-department-title") }}</label
                >
              </div>
            </div>
            <label style="display: flex;align-items: center;">
              <input
                type="checkbox"
                checked
                v-model="statuses"
                true-value="Open"
                :false-value="`Open,Close`"
                @change="handleDateBox"/>
                <span style="font-size: 1.2rem;">{{ $t("user-home-site-on-progress") }}</span>
            </label>

          </div>
          <!-- 현장별 상황판 목록 -->
          <div class="customScroll" style="width: 100%; height: 350px; min-height: 350px; overflow-y: auto;">
            <table class="userEventTable">
              <tr
                  v-for="(data, i) in eventList"
                  :key="i"
                  @click="handleSelectEl(data, i)"
                  :class="{ active: data.eventId == (selectData != null && selectData.eventId) }"
                  style="border-top: 1px solid #f0f0f0;"
                  class="link"
              >
                <!--                  @click="handleEvent(data)"-->
                <td style="width:10%; text-align: center;"  >
                  <img v-if="data.viewId != null" :src="getMissingImg(data.viewId)" alt="icon" style="width: 5rem; height: 5rem;"/>
                </td>
                <td>
                  <div class="flex" style="margin-bottom: 5px; align-items: center;">
                    <b style="font-size: 1.2rem; color: #0080FF; margin-right: 10px;font-weight:400;" >No.{{ data.viewId != null ? data.viewId : data.eventId }}</b>
                    <p class="ev_data" style="font-size: 1.4rem;">{{ data.title }}</p>
                  </div>
                  <div class="eventInfo flexB">
                    <!--{{ data.address + " " + data.addressDetail }}-->
                    <p class="ev_data" style="color:#969696;">{{ data.address }}<span> | </span>{{ data.managerDepartmentTitle}}</p>
                    <!-- <span></span>
                    {{ data.managerName }}
                    <span></span>
                    {{ moment(data.registerDate).format("YYYY.MM.DD") }}-->
                  </div>
                </td>
                <td style="width: 10%;">
                  <div class="site-drone-count">
                    <span ref="deviceCount">{{data.deviceCount == undefined ? 0 : data.deviceCount }} {{$t("overall-data-device-ea") }}</span>
                  </div>
                  <p v-if="accountId=='komipo'" style="color: #969696; text-align: center;">실시간 로봇수</p>
                  <p v-else style="color: #969696; text-align: center;">
                    <img src="@/assets/images/icon_svg/ic_b_drone.png" alt="icon"/> {{ $t("overall-data-now-device-num")}}
                  </p>
                </td>
                <td style="width:10%; text-align: center;">
                  <!--                {{
                                    data.status == "Ready"
                                        ? $t("event-data-status-ready")
                                        : data.status == "Open"
                                            ? $t("event-data-status-on-going")
                                            : data.status == "Close"
                                                ? $t("event-data-status-close")
                                                : "-"
                                  }}-->
                  <img v-if="data.status == 'Ready' " class="statusImg" src="@/assets/images/icon_svg/ic_waiting.svg" />
                  <img v-else-if="data.status == 'Close'" class="statusImg" src="@/assets/images/icon_svg/ic_close.svg" />
                  <img v-else-if="data.status == 'Open'" class="statusImg" src="@/assets/images/icon_svg/ic_on_going.svg" />
                </td>
              </tr>
              <tr class="nodata" v-if="eventList.length == 0">
                <td colspan="9">{{ $t("search-not-found") }}</td>
              </tr>
            </table>
          </div>
          <!-- <div class="pagination" style="margin-top: 10px;">
            <el-pagination
                small
                layout="prev, pager, next"
                :total="total"
                :page-size="size"
                @current-change="handleCurrentChange"
                :current-page="currentPage + 1"
            >
            </el-pagination>
          </div> -->
        </div>
        <div class="box boxCol3 noticeList flexB" style="height: calc(25% - 0px); margin-bottom: 0;">
          <div class="infoWrap" style="width:100%; height: 100%;">
            <h2 class="bold">
              {{ $t("user-home-user-system-notice") }}
            </h2>
            <div class="tableBox" style="height: 129px; margin-top: 1rem; overflow-y: auto;">
              <table class="noticeTable" >
                <tr>
                  <th>No</th>
                  <th>{{ $t("user-home-user-system-notice-contents") }}</th>
                  <th>{{ $t("user-home-user-system-notice-date") }}</th>
                </tr>
                <!--                  <tr
                                      v-for="(data, i) in 11"
                                      :key="i"
                                  >
                                    <td>{{ 11 - (size * currentPage + i) }}</td>
                                    <td>2022.08.15 v1.0 버전 업데이트 안내입니다.</td>
                                    <td>2021.02.02.19:30:00</td>
                                  </tr>-->
                <tr v-for="(data, i) in noticeAppList" :key="i" @click="handleDetail(data.noticeAppId)">
                  <td>{{ noticeAppTotal - (noticeAppCurrentPage * noticeAppPageSize + i) }}</td>
                  <td>{{data.title}}</td>
                  <td>{{ data ? moment(data.registerDate).format("YYYY.MM.DD HH:mm:SS") : "" }}</td>
                </tr>
                <tr v-if="noticeAppList.length === 0">
                  <td colspan="3" style="width: 100%; height:4.7rem; background: white;">
                    <span>{{ $t("data-none-result") }}</span>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div ref="map_div" class="map" style="width: 49.5%;height:852px; border: 1px solid #d3d3d3; min-height: 100%;margin-top:20px;">

        <Map
          ref="map"
          style="width: 100%; height: 100%;"
          :loadMapName="loadMap"
          :isRightLngLat=true
          />
        <MapButtonComponent
        class="live_button"
        ref="buttonMap"/>


        <div v-if="selectData != null" class="box SkyViewInfo" >
          <div>
            <table class="userEventTable" >
              <tr style="background: white;">
                <td style="width:10%; text-align: center; border: none;" >
                  <img v-if="selectData.viewId != null" :src="getMissingImg(selectData.viewId)" alt="icon" style="width: 5rem; height: 5rem;"/>
                </td>
                <td style="border: none;">
                  <div class="site-title-info">
                    <div class="flex" style="align-items: center;">
                      <b style="font-size: 1.2rem; color: #0080FF; margin-right: 10px; font-weight: 400;" >No.{{ selectData.viewId != null ? selectData.viewId : selectData.eventId }}</b>
                      <p class="ev_data bold" style="font-size: 1.4rem; line-height: 1.6rem;">{{ selectData != null ? selectData.title : '-' }}</p>
                    </div>
                    <button class="point" @click="handleEvent(selectData)">
                      들어가기
                    </button>
                  </div>

                  <div class="eventInfo flexB" style="color: #969696;">
                    <!--{{ data.address + " " + data.addressDetail }}-->
                    <p class="ev_data" >{{ selectData != null ? selectData.address + " " + selectData.addressDetail : '-' }}</p>
                    <p style="font-size: 1.2rem;">|</p>
                    <p class="ev_data" >{{ selectData != null ? selectData.managerDepartmentTitle : '-' }}</p>
                    <p style="font-size: 1.2rem;">|</p>
                    <p class="ev_data" >{{ selectData != null ? selectData.managerName : '-' }}</p>
                    <p style="font-size: 1.2rem;">|</p>
                    <p class="ev_data" >{{ selectData != null ? moment(selectData.registerDate).format("YYYY-MM-DD HH:mm") : '-' }}</p>
                  </div>
                </td>
              </tr>
            </table>
          </div>
          <div class="overall" >
            <div class="summary flex" style="border:none; flex-flow: column; padding: 0px; margin-left: 10px; margin-right: 10px;">
              <div class="flex site-detail-list">
                <div class="site-detail-info">
                  <div class="site-detail-title">
                    <img src="@/assets/images/icon_svg/ic_b_drone.png" alt="icon"/>
                    <p> {{ $t("overall-data-now-device-num") }} </p>
                  </div>
                  <h4>
                    <span>
                      {{ deviceCount || 0 }}
                    </span>
                  </h4>
                </div>
                <div class="site-detail-info">
                  <div class="site-detail-title">
                    <img src="@/assets/images/icon_svg/ic_b_drone.png" alt="icon"/>
                    <p> {{ $t("overall-data-cnt-log-drone") }} </p>
                  </div>
                  <h4>
                    <span>
                      {{ deviceLogCount || 0 }}
                    </span>
                  </h4>
                </div>
                <div class="site-detail-info">
                  <div class="site-detail-title">
                    <img src="@/assets/images/icon_svg/ic_b_vod.png" alt="icon"/>
                    <p> {{ $t("overall-data-cnt-log-vod") }} </p>
                  </div>
                  <h4>
                    <span>
                      {{ vodCount || 0 }}
                    </span>
                  </h4>
                </div>
                <div class="site-detail-info">
                  <div class="site-detail-title">
                    <img src="@/assets/images/icon_svg/ic_b_member.png" alt="icon"/>
                    <p> {{ $t("overall-data-cnt-log-member") }} </p>
                  </div>
                  <h4>
                    <span>
                      {{ memberCount || 0 }}
                    </span>
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <transition name="fade">
      <div v-if="visible == 1" class="dim on">
        <SystemNoticeDetail @close="close" :noticeAppId="this.noticeAppId"/>
      </div>
    </transition>
  </div>
</template>

<style>

.main{margin-top: 0;height:100%}
.world-map{display: flex;}
.logoText{
  font-size: 20px;
  font-weight: bolder;
  font-family: NotoSansKR, "나눔고딕", "NanumBarunGothic";
  line-height: 3.8rem;
}
.customScroll{
  overflow-x: auto;
  overflow-y: auto;
  /*overflow-y: hidden;*/
  margin-top: 15px;
}
.customScroll::-webkit-scrollbar {
  height: 10px;
}
/*테이블 클릭 시 배경변경*/
 table tr.active {
  background: #efefef;
}
.userEventTable td{
  height: 68px;
}
.tableBox td{
  height: 44px;
}
.ev_data{
  font-size: 1.2rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
}
.SkyViewInfo{
  width: 90%;
  z-index: 1000;
  position: absolute;
  bottom: 0;
  border-radius: 4px;
  left: 50%;
  transform: translate(-50%, -30px);
}
.cntVal{
  text-align: center;
}
.noticeTable tr td{
  height: 32px;
}
.infoDivision{
  margin-right: 16px !important;
  display: flex;
  flex-direction: row;
}
.infoTitleDiv{
  width: 62%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.infoTitleDiv>img{
  width: 30px;
  height: 30px;
}
.infoTitleDiv>b{
  font-size: 1.2rem;
}
.infoCountDiv{
  width: 38%;
  height: auto;
  display: table;
  text-align: center;
}
.infoCountDiv>span{
  vertical-align: middle;
  display: table-cell;
  horiz-align: right;
  font-size: 24px;
  font-weight: bold;
  color: #0790ff;
}
.portal-btn{padding: 0 0px;margin-top: 20px;margin-bottom: 20px;display: flex;justify-content: space-between;align-items: center;flex-direction: row-reverse;}
.portal-btn > .portal-admin-btn{width: 49%;background: #fff;padding: 15px;border-radius: 5px;border: 0;display: flex;align-items: center;justify-content: space-between;}
.portal-btn > .portal-map-btn{width: 49%;background: #fff;padding: 15px;border-radius: 5px;border: 0;display: flex;align-items: center;justify-content: space-between;}
.portal-btn-content > h2{font-size: 1.4rem;font-weight: 600;margin-bottom: 5px;}
.portal-btn-content > p{font-size: 1.2rem;color: #969696;margin-bottom: 15px;}
.portal-btn-content > button{border: 0;background: #0080ff;color: #fff;padding: 0 30px;border-radius: 5px;}
.portal-btn-content > button:hover{background: #0e73c4;color: white;border-color: #0e73c4;}
.portal-map-btn > img{width: 70px;}
.portal-admin-btn > img{width: 70px;}

.portal-admin-btn.no-portal{display: none;}
.portal-admin-btn.no-portal ~ .portal-map-btn {width: 100%;}
.site-title-info{display: flex;justify-content: space-between;align-items: center;margin-bottom: 5px;}
.site-title-info > button{width: 20%;max-width: 90px;height: 25px;border-radius: 20px;background: #0080FF;border: 1px solid #0080FF;}

.site-detail-list{display: flex;align-items: center;justify-content: space-between;padding-top: 10px;}
.site-detail-info{width: 24%;background: #eee;padding: 10px;border-radius: 5px;}
.site-detail-info > h4{text-align: right;}

.site-detail-title{display: flex;align-items: center;margin-bottom: 5px;justify-content: space-between;}
.site-detail-title > img{margin-right: 5px;}
.site-detail-title > p{color: #969696;font-size: 1.2rem;}
.statusImg{width: 50px;}
.site-drone-count{text-align: right;margin-bottom: 5px;}
table > tr:hover{cursor: pointer;}

</style>

<script>
import Map from "@/components/map/Map";

import moment from "moment";
import {ko} from "vuejs-datepicker/dist/locale";
import {fetchVodList} from "@/api/contents";
import {fetchEventJoinList, fetchEventLiveList} from "@/api/dashboard";
import {fetchDeviceLogList} from "@/api/device";
import {fetchEventList,fetchNoticeAppList} from "@/api/event";
import {fetchUserDetails} from "@/api/user";
import {mapState} from "vuex";
import {format} from "@/mixins/format";
/*import {newTab} from "@/util/popup";*//*새 탭으로 현장별상황판*/

import thum1 from "@/assets/images/thum_s1.png";
import thum2 from "@/assets/images/thum_s2.png";
import thum3 from "@/assets/images/thum_s3.png";
import thum4 from "@/assets/images/thum_s4.png";
import thum5 from "@/assets/images/thum_s5.png";
import thum6 from "@/assets/images/thum_s6.png";

import MapDataType from "@/components/map/enum/MapDataType";
import MapMarkerData from "@/components/map/data/MapMarkerData";
import SystemNoticeDetail from "../../components/pop/SystemNoticeDetail";
import MapButtonComponent from "@/components/map/MapButtonComponent.vue";
import mapButtonEventBus from "@/components/map/mapButtonEventBus";

export default {
  computed: {
    ...mapState({
      isAdmin: state => state.store.isAdmin,
      accountId: state => state.store.accountId,
      userType : state => state.store.userType,

    }),
  },
  components:{ Map, SystemNoticeDetail, MapButtonComponent },

  mixins: [format],
  name: "SiteControl",
  data() {
    return {
      ko: ko,
      editMode: "",
      moment: moment,
      startYmd: null,
      endYmd: null,
      routeUrl:null,
      eventList: [],
      selectData: null,
      thumUrl : null,
      userList: null,
      images:[thum1,thum2,thum3,thum4,thum5,thum6],

      typeChangeShowHeight: 0,
      imgIndex:0,
      total: 0,
      size: 100000000,
      currentPage: 0,
      keywordType: "all",
      keyword: null,
      statuses: "Open",
      sort: "viewId",
      deviceLogCount: 0,
      eventId: null,
      memberCount: 0,
      vodCount: 0,
      deviceCount: 0,
      deviceLiveCountMap: {},

      //시스템 공지
      visible: false,
      noticeAppList:[],
      noticeAppTotal: 0,
      noticeAppCurrentPage: 0,
      noticeAppPageSize: 20,
      noticeAppId: '',

      loadMap: 'kakao',
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight,
      typeChangeMapShowWidth: window.innerWidth - 280,
      typeChangeMapShowHeight: window.innerHeight - 90,

    };
  },
  created() {
    this.$store.dispatch("store/SET_NAVBAR", { nav: 2, sub: 0 });
    window.addEventListener('resize', this.handleResize);
    localStorage.removeItem("selectMenu");

  },
  watch : {
  },
  mounted() {
    this.getEventList();
    this.getUserDetails();
    this.getNoticeAppList();

    this.initButtonMap()
    localStorage.removeItem("LiveEventInfoMain");
    localStorage.removeItem("LiveEventInfoSub");
    
    
  },
  methods: {
    initButtonMap() {
      this.$refs.map.onSetZoomLevel(13);
      this.$refs.buttonMap.showMapTypeChange()
      this.$refs.buttonMap.showMapCenter()
    },
    close() {
      this.visible = false;
    },
    handleDetail(noticeAppId) {
      this.noticeAppId = noticeAppId;
      this.visible = 1;
    },
    getZoomLevel(){
      return this.currentLang == "en" ? 6 : 13
    },
    //페이지 리사이즈
    handleResize() {
      this.windowHeight = window.innerHeight;
      this.typeChangeMapShowHeight = this.windowHeight - 90;
    },
    radioChange(){
      this.search();
    },
    handleDateBox(){
      this.search();
    },
    dateFormat(date) {
      return moment(date).format("YYYY.MM.DD HH:mm:ss");
    },
    customFormatter(date) {
      return moment(date).format("YYYY-MM-DD");
    },
    //이미지 불러오기
    getMissingImg(id){
      return this.images[id.slice(-1) % 6];
    },
    handleSelectEl(data, i) {
      i;
      this.selectData = data;
      this.$refs.map.onSetZoomLevel(6);
      this.$refs.map.onLngLatToMoveCenter(this.selectData.lng, this.selectData.lat);
      this.$refs.map.onSetLngLat(this.selectData.lng, this.selectData.lat);

      this.eventId = data.eventId;
      this.$store.dispatch("store/SET_NAVBAR", { nav: 2, sub: 0 });

      mapButtonEventBus.$emit('selectEventId', this.eventId)

      let location = {
        lng : data.lng,
        lat : data.lat,
      }
      this.createMapMarker(MapDataType.ID_SITE, "MapMarker", "", location, false, null);

      this.getEachLiveDeviceCount(data.eventId);
      this.getDeviceLogCount();
      this.getVodCount();
      this.getEventJoinWorkerCount();
    },
    handleEvent(data) {
      if(!this.$store.state.store.isAdmin && data.managerDepartmentId != this.$store.state.store.departmentId){
        if(data.isAuth == true && data.authCount == 0) {
          alert(this.$t("user-not-permission"));
          return;
        }
      }
      this.$router.push({ path: `${data.eventId}/SkyView`});

      //Site Control 새창
     /* this.routeUrl = this.$router.resolve({ path: `${data.eventId}/SkyView`});
      newTab(this.routeUrl.href, `SkyView_${data.eventId}`);*/
      this.sendData();
    },
    createMapMarker(mapDataType, id, name, location, isMain) {
      let mapMarkerData = new MapMarkerData();
      mapMarkerData.id = id;
      mapMarkerData.lng = location.lng;
      mapMarkerData.lat = location.lat;
      mapMarkerData.isMain = isMain;
      mapMarkerData.name = name;
      if (mapDataType == MapDataType.ID_DRONE) {
        mapMarkerData.rotate = location.azimuth;
      }
      if (mapDataType == MapDataType.ID_USER) {
        var filtr = this.channelList.filter(item => {
          return item.assignedWorkerId == id;
        })
        if (filtr.length > 0) {
          mapMarkerData.isUserLive = true;
        }
      }

      if (this.$refs.map.onIsMarker(mapDataType, id)) {
        this.$refs.map.onModifyMarkerAt(mapDataType, mapMarkerData);
      } else {
        this.$refs.map.onCreateMarker(mapDataType, mapMarkerData);
      }
    },
    handleCurrentChange(val) {
      this.currentPage = val - 1;
      this.getEventList();
    },
    sendData(){
      this.$emit('sendEventData', this.eventList.eventId);
    },
    search(){
      if(
          this.startYmd != null || this.endYmd != null) {
        if (this.startYmd == null || this.endYmd == null || this.startYmd > this.endYmd) {
          return alert(this.$t("alert-message-check-regdate"));
        }
      }
      this.currentPage = 0;
      this.getEventList();
    },
    reset() {
      this.pageNumber = 0;
      this.keywordType = "all";
      this.keyword = null;
      this.statuses =  "Open";
      this.sort = "viewId";
    },
    getEventList() {
      var params = {
        pageNumber: this.currentPage,
        pageSize: this.size,
        keywordType: this.keywordType,
        keyword: this.keyword,
        statuses: this.statuses,
        sort: this.sort
      };

      if(this.startYmd != null && this.endYmd != null) {
        params["dateRange.from"] = moment(this.startYmd).format("YYYY-MM-DD");
        params["dateRange.to"] = moment(this.endYmd).format("YYYY-MM-DD");
      }

      fetchEventList(params).then((res) => {
        this.total = res.data.data.total;
        this.eventList = res.data.data.content;
        this.getLiveDeviceCountList();
        this.eventList.forEach((item)=>{
          item.deviceCount = 0;
          let location = {
            lng : item.lng,
            lat : item.lat
          }
          let isMain = this.selectData != null && item.eventId == this.selectData.eventId;
          this.createMapSiteMarker(MapDataType.ID_SITE_CIRCLE, item.eventId, null, location, isMain);
        })
      });
    },
    getUserDetails() {
      let userId = localStorage.getItem("userId");
      // let token = localStorage.getItem("token");
      fetchUserDetails(userId).then((res) => {
        if (res.data.result == 0) {
          this.userList = res.data.data;
        } else {
          alert(res.message);
        }
      });
    },
    handleRefresh() {
      this.$router.go();
    },
    getDeviceLogCount(){
      let params = {
        pageSize : 1,
        eventId : this.eventId,
        isStartTime : true,
        isEndTime : true,
      };
      if(this.startYmd != null && this.endYmd != null) {
        params["startDateRange.from"] = moment(this.startYmd).format("YYYY-MM-DD");
        params["startDateRange.to"] = moment(this.endYmd).format("YYYY-MM-DD");
      }
      fetchDeviceLogList(params).then((res) => {
        this.deviceLogCount = res.data.data.total;
      });
    },
    getEventJoinWorkerCount(){
      let params = {
        pageSize : 1,
        eventId : this.eventId,
        workerTypes : 'Worker,Controller'
      };
      fetchEventJoinList('worker', params).then((res) => {
        this.memberCount = res.data.data.total;
      });
    },
    getVodCount() {
      let params = {
        eventId: this.eventId,
        pageSize: 1,
      };
      fetchVodList(params).then((res) => {
        this.vodCount = res.data.data.total;
      });
    },
    async getLiveDeviceCountList(){
      let eventIds = this.eventList.map((item) => item.eventId).join(",");
      let params = {
        eventIds : eventIds,
      };
      await fetchEventLiveList('device', params).then((res) => {
        if (res.data.result == 0) {
          let eventLiveList = res.data.data.content;
          this.eventList.forEach((item, index) => {
              let filter = eventLiveList.filter((filter) => filter.eventId == item.eventId);
              item.deviceCount = filter.length;
            this.$set(this.eventList, index, this.eventList[index]);
          });
        }
      });
    },
    getEachLiveDeviceCount(eventId){
      var result = 0;
      let findIndex = this.eventList.findIndex((item) => item.eventId == eventId);
      if(findIndex!= -1) {
        result = this.eventList[findIndex].deviceCount;
      }
      this.deviceCount = result;
    },
    getNoticeAppList(){
      let params = {
        pageNumber: this.noticeAppCurrentPage,
        pageSize: this.noticeAppPageSize,
        searchDate: moment(new Date()).format("YYYY-MM-DD"),
        status: "Used",
      };
      fetchNoticeAppList(params).then((res)=>{
        this.noticeAppList = res.data.data.content;
        this.noticeAppTotal = res.data.data.total;
      });
    },

    workerClick() {
      this.worker = true;
      if (this.worker) {
        this.risk = false;
      }
    },
    riskClick() {
      this.risk = true;
      if (this.risk) {
        this.worker = false;
      }
    },
    toggleAccordion(index) {
      if (this.activeIndex === index) {
        this.activeIndex = null;
      } else {
        this.activeIndex = index;
      }
    },
    getHomeUrl(){
      /* 현장별 상황판 새 탭일 경우 홈
      var resultUrl = `/${this.accountId}/eventControl/${this.eventId}/SkyView`;
      return resultUrl*/
      var resultUrl = ""

      switch (this.userType){
        case "Admin" :
          resultUrl = `/${this.accountId}/account/portal`;
          break;
        case "Manager" :
          resultUrl = `/${this.accountId}/account/portal`;
          // resultUrl = `/${this.accountId}/dashboard/DroneStatusMap`;
          break;
        case "User" :
          resultUrl = `/${this.accountId}/account/portal`;
          break;
      }

      return resultUrl
    },
    createMapSiteMarker(mapDataType, id, name, location, isMain) {
      let mapMarkerData = new MapMarkerData();
      mapMarkerData.id = id;
      mapMarkerData.lng = location.lng;
      mapMarkerData.lat = location.lat;
      mapMarkerData.isMain = isMain;
      mapMarkerData.name = name;
      this.$refs.map.onCreateMarker(mapDataType, mapMarkerData);
    },

    moveToSiteMap(){
      window.open(
          `/${this.accountId}/dashboard/DroneStatusMap`,
          `_blank`
      );
    },
    //관리자 권한
    moveToAdminMain(){
      if(this.userType === "Admin"){
        this.$router.push({ name: "overallAdmin" });
      }
    },
  },
};
</script>
